const ENVIRONMENT = {
  // HOST
  EXPLORER_URL: process.env.REACT_APP_EXPLORER_URL,
  BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
  // CHAIN
  CHAIN_ID: process.env.REACT_APP_CHAIN_ID,
  CHAIN_DECIMALS: process.env.REACT_APP_CHAIN_DECIMALS,
  CHAIN_NAME: process.env.REACT_APP_CHAIN_NAME,
  CHAIN_SYMBOL: process.env.REACT_APP_CHAIN_SYMBOL,
  // RPC
  RPC_URL: process.env.REACT_APP_RPC_URL,
  // TELEGRAM BOT
  TELEGRAM_BOT: process.env.REACT_APP_TELEGRAM_BOT,
  // ENCRYPTION
  IS_ENCRYPTION: process.env.REACT_APP_IS_ENCRYPTION,
  STRING: process.env.REACT_APP_STRING,
};

const GAME_INFO = {
  LOGO_URL: "https://d31t17jekhn3g5.cloudfront.net/home-logo-new.png",
  FULL_NAME: "Escape Earth",
  SHORT_NAME: "Crossfi",
  COIN_SYMBOL: "E2",
  COIN_SYMBOL1: "XFI",
};

export { ENVIRONMENT, GAME_INFO };
