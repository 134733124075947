import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import "./index.scss";
import * as MetaMaskSDK from "@metamask/sdk";
import { useNavigate, useParams } from "react-router-dom";
import { apiCallPost } from "../../Client/axios";
import Button from "../../Components/Button/Button";
import Loader from "../../Components/loader/loader";
import { CopyIcon } from "../../Assets/icons";
import { ENVIRONMENT, GAME_INFO } from "../../Utils/constants";

const ENV_CHAIN_ID = ENVIRONMENT.CHAIN_ID;

export default function WalletConnection() {
  const navigate = useNavigate();
  const { connectionId } = useParams();

  const [sdk, setSdk] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [inAppBrowser, setInAppBrowser] = useState(false);
  const [loader, setLoader] = useState(false);
  const [provider, setProvider] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [copied, setCopied] = useState(false);


  const alreadyProcessing = sdk && provider;

  useEffect(() => {
    const validateToken = async () => {
      const url = `${ENVIRONMENT.BACKEND_URL}/validate-token`;
      const body = { token: connectionId };
      const response = await apiCallPost(url, body);

      if (response.error) {
        navigate("/error");
      }
    };

    if (connectionId) validateToken();
  }, [connectionId, navigate]);

  useEffect(() => {
    setInAppBrowser(!!window.TelegramWebview);
  }, []);

  const initializeSdk = () => {
    try {
      const sdkInstance = new MetaMaskSDK.MetaMaskSDK({
        dappMetadata: {
          name: "Gaming App",
          url: "https://stage-wallet.escapearth.com/",
        },
        logging: { sdk: false },
      });
      setSdk(sdkInstance);
    } catch (error) {
      console.error("SDK Initialization Error:", error);
    }
  };

  const getAccountInfo = async () => {
    if (!window.ethereum) {
      console.log("MetaMask is not installed.");
      return null;
    }
    try {
      const accountsList = await window.ethereum.request({ method: "eth_accounts" });
      const account = accountsList?.[0];
      if (account) {
        const chainId_ = await window.ethereum.request({ method: "eth_chainId" });
        setChainId(chainId_);
        setAccounts(account);
      } else {
        console.log("No accounts found. MetaMask might not be connected.");
      }
    } catch (error) {
      console.error("Error fetching account info:", error);
    }
  };

  const connectWallet = async () => {
    if (!sdk) {
      console.error("SDK is not initialized");
      return;
    }
    try {
      const providerInstance = await sdk.getProvider();
      setProvider(providerInstance);

      if (providerInstance) {
        const accountsList = await providerInstance.request({ method: "eth_requestAccounts" });
        const currentChainId = await providerInstance.request({ method: "eth_chainId" });

        setChainId(currentChainId);
        setAccounts(accountsList[0]);
      } else {
        console.error("Provider is not available");
        resetSdk();
      }
    } catch (error) {
      console.error("Error during wallet connection:", error);
      if (error.code === -32002) {
        toast.success("Request for Wallet is already pending. Please open MetaMask", { className: "warning" });
      }
    }
  };

  const handleNetworkAddition = async () => {
    try {
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: ENV_CHAIN_ID,
            chainName: ENVIRONMENT.CHAIN_NAME,
            blockExplorerUrls: [ENVIRONMENT.EXPLORER_URL],
            nativeCurrency: { symbol: ENVIRONMENT.CHAIN_SYMBOL, decimals: Number(ENVIRONMENT.CHAIN_DECIMALS ?? 18) },
            rpcUrls: [ENVIRONMENT.RPC_URL],
          },
        ],
      });
      toast.success(`${ENVIRONMENT.CHAIN_NAME} network added successfully`);
      const updatedChainId = await provider.request({ method: "eth_chainId" });
      setChainId(updatedChainId);
    } catch (error) {
      console.error("Error adding network:", error);
    }
  };

  const validateAddress = async () => {
    const url = `${ENVIRONMENT.BACKEND_URL}/address`;
  
    const body = {
      symbol: GAME_INFO?.COIN_SYMBOL1,
      address: accounts,
      token: connectionId,
    };
  
    try {
      setLoader(true);
      const fetchRes = await apiCallPost(url, body);
      console.log("fetchRes :: ", fetchRes);
  
      if (fetchRes?.status === 200) {
        window.location.href = ENVIRONMENT?.TELEGRAM_BOT;
      } else {
        console.error("Error: Validation failed.");
        navigate("/error");
      }
    } catch (error) {
      console.error("API call error:", error);
      navigate("/error");
    } finally {
      setLoader(false);
    }
  };

  const redirectToGame = () => {
    validateAddress();
    setTimeout(() => window.close(), 2000);
  };

  useEffect(() => {
    clearCache();
    initializeSdk();
  }, []);

  const resetSdk = () => {
    setSdk(null);
    setProvider(null);
    initializeSdk();
  };

  const clearCache = () => {
    document.cookie.split(";").forEach(c => {
      document.cookie = `${c.trim().split("=")[0]}=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
    });
    if (caches) {
      caches.keys().then(names => names.forEach(name => caches.delete(name)));
    }
  };
  
  const copyAddress = () => {
    if (accounts && !copied) {
      navigator.clipboard.writeText(accounts)
        .then(() => {
          toast.success("Copied successfully", { className: "success" });
          setCopied(true);
          setTimeout(() => setCopied(false), 3000);
        })
        .catch((error) => {
          toast.error("Failed to copy address",{className:"error"});
          console.error("Copy error:", error);
        });
    }
  };


  return (
    <div className="btn-container">
      <div className="in">
        <div className="steps_form">
          <ul>
            <li><button className={`${accounts ? "done" : "active"}`}>Step 1</button></li>
            <li><button className={`${chainId === ENV_CHAIN_ID.toLocaleLowerCase() ? "done" : "active"}`}>Step 2</button></li>
          </ul>
        </div>
        <center>
          {inAppBrowser ? (
            <div className="steps_form_content">
              <p className="content mb-4">Please disable In-App Browser of Telegram to Connect Wallet.</p>
              <div className="content mb-4">
                <p><b>Note:</b> Follow these steps to disable In-App Browser:</p>
                <ol className="mt-3 text-start">
                  <li>Click on the 3 dots on the top, then click Settings.</li>
                  <li>Toggle off the Enable In-App Browser setting.</li>
                  <li>Close this window and click Wallet Connect.</li>
                </ol>
              </div>
            </div>
          ) : accounts ? (
            <div className="steps_form_content">
              {loader && <Loader />}
              <p className="content mb-4">You have successfully connected your MetaMask wallet.</p>
              <p className="content mb-4">Current Chain ID: {chainId}</p>
              {chainId !== ENV_CHAIN_ID?.toLowerCase() && (
                <Button onClick={handleNetworkAddition}>Add {GAME_INFO.SHORT_NAME} Network</Button>
              )}
              <div className="copy_area">
                <p>{accounts.slice(0, 4)}...{accounts.slice(-4)}</p>
                <Button onClick={copyAddress}><CopyIcon /></Button>
              </div>
              {chainId === ENV_CHAIN_ID?.toLowerCase() && (
                <Button fluid className="mt-4 p-0" onClick={redirectToGame}>Click here to redirect to the game.</Button>
              )}
            </div>
          ) : (
            <div className="steps_form_content">
              <p className="content mb-4">Click the button below to connect to MetaMask.</p>
              <div className="content mb-4">Click the button to add the "{GAME_INFO.SHORT_NAME} Network" to MetaMask.</div>
              <div className="content note mb-4">
                <b>Note:</b> If you're on a mobile device, clear your browser cache if you don't see a popup to connect MetaMask.
              </div>
              <div className="action_btn">
                {alreadyProcessing ? (
                  <Button disabled>Connecting...</Button>
                ) : (
                  <Button onClick={connectWallet}>Connect Wallet</Button>
                )}
                <Button onClick={handleNetworkAddition} disabled={chainId !== ENV_CHAIN_ID?.toLowerCase()}>
                  Add {GAME_INFO.SHORT_NAME} Network
                </Button>
              </div>
            </div>
          )}
        </center>
      </div>
    </div>
  );
}