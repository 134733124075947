import { ENVIRONMENT } from "./constants";

/**
 * Verify uuid (v4)
 * @param {string} id
 * @returns boolean
 */
export const verifyUuid = (id) => {
  const alternativeUuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  const trimmedId = id?.trim();
  return alternativeUuidRegex.test(trimmedId);
};

function isPrime(number) {
  if (number <= 1) {
    return false;
  }

  if (number <= 3) {
    return true;
  }

  if (number % 2 === 0 || number % 3 === 0) {
    return false;
  }

  for (let i = 5; i * i <= number; i += 6) {
    if (number % i === 0 || number % (i + 2) === 0) {
      return false;
    }
  }

  return true;
}

function getPrimeNumbersInRange(start, end) {
  const primeNumbers = [];

  for (let number = start; number <= end; number++) {
    if (isPrime(number)) {
      primeNumbers?.push(number);
    }
  }
  return primeNumbers;
}

export function getKey(value) {
  const key = ENVIRONMENT.STRING;

  // get the prime number
  const primeNumbers = getPrimeNumbersInRange(1, value);

  // get the string according to the prime number

  const string = primeNumbers.map((number) => key[number])?.join("");
  return string;
}
