import axios from "axios";
import * as CryptoJS from "crypto-js";
import { getKey } from "../Utils/index";
import { ENVIRONMENT } from "../Utils/constants";

const IS_ENCRYPTION = Number(ENVIRONMENT.IS_ENCRYPTION);
const BASE_URL = "";
const key = getKey(53);
const axiosApi = axios.create({
  baseURL: BASE_URL,
});

axiosApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (config?.headers?.noAuth === "true") {
      config.headers["api-access-token"] = `${token}`;
    } else if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export function encryption(payload) {
  try {
    if (payload != undefined || payload != null) {
      const keyBytes = CryptoJS.enc.Utf8.parse(key);
      const cipherText = CryptoJS.AES.encrypt(payload, keyBytes, {
        mode: CryptoJS.mode.ECB,
      }).toString();

      return cipherText;
    }
  } catch (error) {
    return error;
  }
}

// decoding
export function decryption(payload) {
  try {
    if (payload) {
      const keyBytes = CryptoJS?.enc?.Utf8?.parse(key);
      const bytes = CryptoJS?.AES.decrypt(payload, keyBytes, {
        mode: CryptoJS.mode.ECB,
      });
      const decryptData = bytes?.toString(CryptoJS?.enc?.Utf8);
      return decryptData;
    }
  } catch (error) {
    console.log(error);
  }
}

export const encryptionFilter = (data) => {
  if (!data?.entries && data?.entries === undefined) {
    // Ensure the payload is properly handled for encryption
    const encD = IS_ENCRYPTION ? encryption(JSON?.stringify(data)) : data;

    return IS_ENCRYPTION ? { reqData: encD } : data;
  } else {
    return data;
  }
};


export const decryptionFilter = (data) => {
  if (data && data?.resData && typeof data?.resData === "string") {
    // Ensure decryption returns a valid string before parsing
    const decryptedData = decryption(data?.resData);
    
    if (decryptedData) {
      try {
        const decD = IS_ENCRYPTION ? JSON.parse(decryptedData) : data;
        return IS_ENCRYPTION ? decD : data;
      } catch (error) {
        console.error("Error parsing JSON in decryptionFilter:", error);
        return data; // Return original data if parsing fails
      }
    } else {
      console.error("Decryption returned null or undefined.");
      return data;
    }
  } else {
    return data;
  }
};


function handleSuccess(res) {
  if (res?.status === 200 || res?.status === 201) {
    // Handle success messages here
  }
  if (res.status === 403 || res.status === 400) {
    // Handle warnings here
  }
}

async function apiCallGet(url, header, toastOn = false) {
  try {
    const response = await axiosApi.get(url, header);
    const decryptData = decryptionFilter(response?.data);

    if (toastOn) {
      handleSuccess(decryptData);
    }

    return decryptData;
  } catch (error) {
    const decryptData = decryptionFilter(error?.response?.data);
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
    }
    return decryptData;
  }
}

async function apiCallPost(url, data, toastOn = true) {
  const dataFiltered = encryptionFilter(data);
  try {
    const resp = await axiosApi.post(url, dataFiltered, {});
    const decryptData = decryptionFilter(resp?.data);

    if (toastOn) {
      handleSuccess(decryptData);
    }

    return decryptData;
  } catch (error) {
    const decryptData = decryptionFilter(error?.response?.data);
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }
    return decryptData;
  }
}

async function apiCallPatch(url, data, toastOn = true, loader = false) {
  const dataFiltered = encryptionFilter(data);
  try {
    const resp = await axiosApi.patch(url, dataFiltered);
    const decryptData = decryptionFilter(resp?.data);

    if (toastOn) {
      handleSuccess(decryptData);
    }
    return decryptData;
  } catch (error) {
    const decryptData = decryptionFilter(error?.response?.data);
    if (error?.response?.status === 401) {
      localStorage.clear();
    }
    return decryptData;
  }
}

async function apiCallDelete(url, header, toastOn = false) {
  try {
    const response = await axiosApi.delete(url, header);
    const decryptData = decryptionFilter(response?.data);

    if (toastOn) {
      handleSuccess(decryptData);
    }

    return decryptData;
  } catch (error) {
    const decryptData = decryptionFilter(error?.response?.data);
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
    }
    return decryptData;
  }
}

export { apiCallGet, apiCallPost, apiCallPatch, apiCallDelete };
