import React from "react";
import logo from "../../Assets/homelogo.png";
import img1 from "../../Assets/image1.png";
import img2 from "../../Assets/image2.png";
// import img3 from "../../Assets/image3.png";
import img4 from "../../Assets/image4.png";
import img5 from "../../Assets/image5.png";
import img6 from "../../Assets/image6.png";
import img7 from "../../Assets/image7.png";
import img8 from "../../Assets/image8.png";
import img9 from "../../Assets/image9.png";
import img10 from "../../Assets/image10.jpg";
import img11 from "../../Assets/image11.png";
import { Table } from "react-bootstrap";
import "./Help.scss";

const SHIDO_COINS_FOR_LEVELS = [
  {
    level: "Level 01: Shido Apprentice",
    inGamePoints: "1M",
    shidoCoins: "10",
  },
  {
    level: "Level 02: Token Tinkerer",
    inGamePoints: "3M",
    shidoCoins: "20",
  },
  {
    level: "Level 03: Crypto Climber",
    inGamePoints: "8M",
    shidoCoins: "30",
  },
  {
    level: "Level 04: Staking Squire",
    inGamePoints: "15M",
    shidoCoins: "50",
  },
  {
    level: "Level 05: DeFi Explorer",
    inGamePoints: "24M",
    shidoCoins: "80",
  },
  {
    level: "Level 06: Contract Coder",
    inGamePoints: "35M",
    shidoCoins: "130",
  },
  {
    level: "Level 07: Blockchain Builder",
    inGamePoints: "50M",
    shidoCoins: "210",
  },
  {
    level: "Level 08: Rule Maker",
    inGamePoints: "70M",
    shidoCoins: "340",
  },
  {
    level: "Level 09: Tokenomics Titan",
    inGamePoints: "90M",
    shidoCoins: "550",
  },
  {
    level: "Level 10: Shido Master",
    inGamePoints: "150M",
    shidoCoins: "890",
  },
];

const SHIDO_COINS_FOR_DAILY_REWARDS = [
  {
    day: 1,
    points: 50,
    shidoCoins: 10,
  },
  {
    day: 2,
    points: 100,
    shidoCoins: 20,
  },
  {
    day: 3,
    points: 150,
    shidoCoins: 30,
  },
  {
    day: 4,
    points: 200,
    shidoCoins: 50,
  },
  {
    day: 5,
    points: 250,
    shidoCoins: 70,
  },
  {
    day: 6,
    points: 300,
    shidoCoins: 100,
  },
  {
    day: 7,
    points: 350,
    shidoCoins: 130,
  },
  {
    day: 8,
    points: 400,
    shidoCoins: 170,
  },
  {
    day: 9,
    points: 450,
    shidoCoins: 200,
  },
  {
    day: 10,
    points: 500,
    shidoCoins: 250,
  },
];

const Help = () => {
  return (
    <div className="help">
      <div class="container">
        <div class="logo_">
          <img src={logo} alt="logo" />
          <h1>Shido Guide</h1>
        </div>
        <h3 class="version">Edition of 07.08.2024</h3>
        <h2 class="title my-3">Overview</h2>
        <p class="content">
          SHIDO_COINS_FOR_DAILY_REWARDS The Shido tapping game lets you earn
          in-game coins by tapping and advancing through levels. It combines fun
          gameplay with real cryptocurrency rewards. Built on a secure platform,
          it includes decentralized finance features and gives you a chance to
          win real Shido tokens.{" "}
        </p>
        <h2 className="title">How to play </h2>
        <div className="gap-3 d-md-flex align-items-start img_box">
          <p className="content">
            You need to tap on the Shido logo to earn in-game coins per tap. All
            your earnings will be displayed on the main screen.
          </p>
          <div>
            <img src={img11} alt="guide-image" />
            <p className="content text-center">Upgrade, Coins, and earns</p>
          </div>
        </div>
        <p className="content">
          When you level up in your ultimate levels by collecting a specific
          amount of in-game coins, it results in rewards that increase your
          energy limits.
        </p>

        <div className="gap-3 d-md-flex align-items-start justify-content-between mt-4">
          <img src={img2} alt="help-guide-image" />
          <img src={img5} alt="help-guide-image" />
        </div>
        <Table responsive className="mt-5">
          <thead>
            <tr>
              <th>Lvl</th>
              <th>Lvl Name</th>
              <th>Balance needed to unlock</th>
            </tr>
          </thead>
          <tbody>
            {[
              {
                lvl: 1,
                name: "Shido Apprentice",
                balance: "0",
                energy: 2000,
                perTapIncome: 5,
              },
              {
                lvl: 2,
                name: "Token Tinkerer",
                balance: "1M",
                energy: 2500,
                perTapIncome: 6,
              },
              {
                lvl: 3,
                name: "Crypto Climber",
                balance: "3M",
                energy: 3000,
                perTapIncome: 7,
              },
              {
                lvl: 4,
                name: "Staking Squire",
                balance: "8M",
                energy: 3500,
                perTapIncome: 8,
              },
              {
                lvl: 5,
                name: "Defi Explorer",
                balance: "15M",
                energy: 4000,
                perTapIncome: 10,
              },
              {
                lvl: 6,
                name: "Contract Coder",
                balance: "24M",
                energy: 4500,
                perTapIncome: 12,
              },
              {
                lvl: 7,
                name: "Blockchain Builder",
                balance: "35M",
                energy: 5000,
                perTapIncome: 14,
              },
              {
                lvl: 8,
                name: "Rule Maker",
                balance: "50M",
                energy: 5500,
                perTapIncome: 16,
              },
              {
                lvl: 9,
                name: "Tokenomics Titan",
                balance: "70M",
                energy: 6000,
                perTapIncome: 18,
              },
              {
                lvl: 10,
                name: "Shido Master",
                balance: "90M",
                energy: 6500,
                perTapIncome: 20,
              },
            ].map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.lvl}</td>
                  <td>{item.name}</td>
                  <td>{item.balance}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="gap-3 d-md-flex align-items-start justify-content-between mt-4">
          <img src={img1} alt="help-guide-image" />
        </div>
        <div className="gap-3 mt-5 d-md-flex align-items-start justify-content-between">
          <div>
            <h3>Energy count and Points Per Tap:</h3>
            <ul>
              <li>
                By default, you have an energy count of 2000, which means you
                can tap 2000 times. It will take 8 to 9 minutes to refill from 0
                to 2000.
              </li>
              <li>
                It will increase as you progress through the game and reach
                different ultimate levels.
              </li>
            </ul>
            <Table responsive className="mt-4">
              <thead>
                <tr>
                  <th>LVL</th>
                  <th>Lvl Name</th>
                  <th>Points Per Tap</th>
                  <th>Energy count</th>
                </tr>
              </thead>
              <tbody>
                {[
                  {
                    level: 1,
                    name: "Shido Apprentice",
                    tapPoints: 5,
                    energy: 2000,
                  },
                  {
                    level: 2,
                    name: "Token Tinkerer",
                    tapPoints: 6,
                    energy: 2500,
                  },
                  {
                    level: 3,
                    name: "Crypto Climber",
                    tapPoints: 7,
                    energy: 3000,
                  },
                  {
                    level: 4,
                    name: "Staking Squire",
                    tapPoints: 8,
                    energy: 3500,
                  },
                  {
                    level: 5,
                    name: "Defi Explorer",
                    tapPoints: 10,
                    energy: 4000,
                  },
                  {
                    level: 6,
                    name: "Contract Coder",
                    tapPoints: 12,
                    energy: 4500,
                  },
                  {
                    level: 7,
                    name: "Blockchain Builder",
                    tapPoints: 14,
                    energy: 5000,
                  },
                  { level: 8, name: "Rule Maker", tapPoints: 16, energy: 5500 },
                  {
                    level: 9,
                    name: "Tokenomics Titan",
                    tapPoints: 18,
                    energy: 6000,
                  },
                  {
                    level: 10,
                    name: "Shido Master",
                    tapPoints: 20,
                    energy: 6500,
                  },
                ].map((item) => {
                  return (
                    <tr key={item.level}>
                      <td>{item.level}</td>
                      <td>{item.name}</td>
                      <td>{item.tapPoints}</td>
                      <td>{item.energy}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <img src={img8} alt="help-guide-image" />
        </div>
        <div className="mt-5 d-md-flex align-items-start gap-4">
          <div>
            <h3>Booster</h3>
            <ul>
              <li>You have 6 boosters available per day.</li>
              <li>
                When you exhaust your tapping power and want to continue
                immediately, you can use Full Energy to bypass the
                auto-refilling time.
              </li>
              <li>
                After using boosters to update your energy, you must wait 1 hour
                before using them again.
              </li>
            </ul>
          </div>
          <div className="img">
            <img src={img4} alt="" />
            <b className="d-block text-center">
              You can also get extra in-game coins in the earn section
            </b>
          </div>
        </div>
        <div className="mt-4 d-md-flex align-items-start gap-4">
          <div>
            <p className="content">
              In the Everyday game, you can earn extra in-game coins from the
              daily reward section. The number of in-game coins you receive
              increases each day, and on the 11th day, the countdown resets.
            </p>
            <p className="content">
              <b>Note:</b> Your progress will be reset and you will have to
              start collecting coins from scratch if you skip at least one day.
            </p>
          </div>
          <img src={img10} alt="" />
        </div>
        <div>
          <h3>Daily reward</h3>
          <img src={img6} alt="" />
        </div>
        <div className=" mt-5 d-md-flex align-items-start gap-4">
          <div>
            <h3>Daily challenges</h3>
            <p className="content">
              You will be shown a black and white Shido logo, and by tapping
              rapidly on it, you can fill it with blue colour. You will have
              only 20 seconds to complete the task. Completing the task will
              award you 500 in-game coins.
            </p>
          </div>
          <div className="img">
            <img src={img7} alt="" />
          </div>
        </div>
        <div className="mt-4 d-md-flex align-items-start gap-4">
          <div>
            <h3>Referral program</h3>
            <p className="content">
              In the "Friends" section, you can send an invitation link. When
              your friend clicks on it, their name will appear at the bottom of
              your friend's list.
            </p>
            <p className="content">
              For each referral, a user will receive certain in-game coins.
            </p>
          </div>
          <div className="img">
            <img src={img9} alt="" />
          </div>
        </div>

        {/* Shido Coins Distribution  */}
        <div className="mt-4  ">
          <div>
            <h3>
              Shido Coins as a reward can be earned on the below scenarios:
            </h3>
            <ol>
              <li className="content">
                <p>
                  On achieving a <strong>higher ultimate level</strong>, the
                  user will earn specified Shido tokens as explained in the
                  table 1 below
                </p>
              </li>
              <li className="content">
                <p>
                  On <strong>inviting a friend</strong>, the user will receive
                  Shido tokens
                </p>
              </li>
              <li className="content">
                <p>
                  Get shido tokens on <strong>daily rewards</strong> earnings as
                  explained in the table 2 below
                </p>
              </li>
            </ol>
          </div>
          <div>
            <h3>Ultimate Levels: (Table 1)</h3>
            <p className="content">
              As the User collects a specific amount of in-game coins and
              completes tasks, they will progress to the next level in the game.
            </p>
            <p className="content">
              Note: The in-game coins target to achieving levels can be
              configured. As the user progresses to the next level, it will also
              increase the tapping count.
            </p>
            <Table responsive className="mt-4">
              <thead>
                <tr>
                  <th>Ultimate Level / Name</th>
                  <th>On achieving In-game coins/points*</th>
                  <th>Shido Coins*</th>
                </tr>
              </thead>
              <tbody>
                {SHIDO_COINS_FOR_LEVELS.map((item) => {
                  return (
                    <tr key={item.level}>
                      <td>{item.level}</td>
                      <td>{item.inGamePoints}</td>
                      <td>{item.shidoCoins}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div>
            <h3>Invite Friend:</h3>
            <p className="content">
              Users can invite their friends through different social media
              platforms, earning 500 in-game coins per invite and{" "}
              <strong>20 Shido Coins.*</strong>
            </p>
          </div>
          <div>
            <h3>Daily Reward (Table 2)</h3>
            <p className="content">
              In this user will receive daily coins and SHIDO tokens as below:
            </p>
            <Table responsive className="mt-4">
              <thead>
                <tr>
                  <th>Days</th>
                  <th>In game coins/points earning*</th>
                  <th>SHIDO Coins*</th>
                </tr>
              </thead>
              <tbody>
                {SHIDO_COINS_FOR_DAILY_REWARDS.map((item) => {
                  return (
                    <tr key={item.day}>
                      <td>{item.day}</td>
                      <td>{item.points}</td>
                      <td>{item.shidoCoins}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <h3>
              *The number of coins offered as a reward is subject to change
            </h3>
          </div>
        </div>

        <h3>Metamask</h3>
        <p className="content">
          You can connect your MetaMask wallet to collect Shido tokens.
        </p>
      </div>
    </div>
  );
};

export default Help;
