import React from "react";
import "./index.css";
import { GAME_INFO } from "../../Utils/constants";

const Header = () => {
    return (
        <header className="header">
            <div className="header-content">
                <img src={GAME_INFO.LOGO_URL} alt="Logo" className="logo" />
                {/* <h1 className="title">{GAME_INFO.FULL_NAME}</h1> */}
            </div>
        </header>
    );
};

export default Header;
